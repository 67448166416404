.memberWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    gap: 30px;
    margin: 30px 0;
    align-self: stretch;
    .contactsInner {
        
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--6, #FADCEA);
        background: var(--7, #FFF5FA);

        h1 {
            width: 886px;
            height: 22px;
            color: var(--4, #E164A0);

            /* Basic/Subtitle */
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            /* 144.444% */
        }

        .contactDetails {
            display: flex;
            align-items: flex-start;
            gap: 5px;
            align-self: stretch;
            flex-wrap: wrap;

            .textWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                // flex: 1 0 0;
                flex-basis: 100%;
                // margin: 10px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .memberWrapper {
        flex-direction: column;
    }
}