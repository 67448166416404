.modal-loading {
    .ant-modal-content{
        opacity: 50%;
    }
}

.modal-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 100%;
  }