//tabs design 
.visitBoardComponent,
.implementationPage {
    .ant-tabs-tab {
        color: #777777;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-tab:hover {
        color: var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--2, #AA1A5F) !important;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-ink-bar-animated {
        color: var(--2, #AA1A5F) !important;

        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active {
        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }


    //buttons



    // .addButtonTPM {
    //     background: var(--1, #6A113C) !important;
    // }

    .exportButtonTPM,
    .exportButtonTPM:hover {
        background: var(--2, #6A113C) !important;
    }

    .actionDiv {
        justify-content: space-evenly;
    }

    .tableViewcontainer {
        margin: 40px 40px 40px 40px;
    }


    .tableHeader {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 80px;
        align-self: stretch;
        margin-bottom: 40px;


    }

    .tableViewHeaderText {
        color: var(--2, #AA1A5F);
        /* Basic/Title */
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        /* 145.455% */
    }

    .tableViewHeaderButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;
        flex: 1 0 0;
    }

    .customTabs .ant-tabs-tab {
        color: var(--Neutrals-Gray-400, #777);
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .customTabs .ant-tabs-tab-active {
        color: var(--2, #AA1A5F);
    }

    .filtersWrapper {
        margin: 40px 0px;
    }

    // Status style

    .progress {
        width: fit-content;
        display: flex;
        padding: 14px 10px !important;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #EBF2FF;

        span {
            color: #3B81FE;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }





    .review {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #F5F1F8;

        span {
            color: #9670B8;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    // .delivered {

    // }





    thead {

        .styledTitle {
            text-wrap: nowrap;
        }



        tr {

            background: var(--7, #FFF5FA) !important;

            border-top: 1px solid var(--6, #FADCEA) !important;
        }

    }

    .filtersContainer {
        width: 100%;
        gap: 24px;
    }

}



// Style Sorter 
.ant-table-column-sorter.ant-table-column-sorter-full {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    .anticon.anticon-caret-up.ant-table-column-sorter-up,
    .anticon.anticon-caret-down.ant-table-column-sorter-down {
        margin: 0 !important;
        padding: 0 !important;
        color: #aa1a6042;
    }


    .anticon.anticon-caret-down.ant-table-column-sorter-down.active,
    .anticon.anticon-caret-up.ant-table-column-sorter-up.active {
        margin: 0 !important;
        padding: 0 !important;
        color: #AA1A5F;
    }
}



.ant-table-column-sorters {
    display: flex;
    gap: 10px;
}


thead {


    .ant-table-selection {

        .ant-checkbox {

            input[type="checkbox"] {
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                position: absolute;
                width: 18px;
                height: 18px;
                margin: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    border: 1px solid var(--2, #AA1A5F);
                    background-color: transparent;
                    position: absolute;
                    top: -9px;
                    left: -2px;
                }

                &::after {
                    content: "\2713";
                    position: absolute;
                    // top: 50%;
                    left: 40%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: var(--2, #AA1A5F);
                    opacity: 0;
                    font-weight: bold;
                    transition: opacity 0.3s ease;
                }

                &:checked {
                    &::before {
                        border: 2px solid var(--2, #AA1A5F);
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .ant-checkbox-inner {
            display: none;
        }
    }
}

.ant-table-selection-column {
    position: relative !important;
}


.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
    background: var(--7, #FFF5FA);
}

tbody {



    .ant-checkbox-wrapper {
        min-width: 15px !important;

        .ant-checkbox {

            input[type="checkbox"] {
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                width: 18px;
                height: 18px;
                margin: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    border: 1px solid var(--Neutrals-Gray-200, #C1C1C1);
                    background-color: transparent;
                    position: absolute;
                    top: -9px;
                    left: 5px;
                }

                &::after {
                    content: "\2713";
                    position: absolute;
                    // top: 50%;
                    left: 80%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: var(--Neutrals-Gray-200, #656565);
                    opacity: 0;
                    font-weight: bold;
                    transition: opacity 0.3s ease;
                }

                &:checked {
                    &::before {
                        border: 2px solid var(--Neutrals-Gray-200, #656565);
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .ant-checkbox-inner {
            display: none;
        }
    }



}


thead {
    .ant-table-cell-fix-right-first {
        filter: drop-shadow(-1px 0px 5px #9f9e9e);
        width: 160px !important;
    }
}


/* Custom styles for the fixed column */
@media (max-width: 576px) {
    .ant-table-cell-fix-right {
        position: sticky !important;
        right: 0;
        background-color: white;
        z-index: 1;
    }

    .actionDiv {
        display: flex;
        justify-content: space-around;
    }
}

/* Ensuring the fixed column is visible on all screen sizes */
.ant-table-cell-fix-right {
    background-color: white;
    z-index: 1;
}


.ant-picker-cell-selected::before {
    color: var(--3, #D42177) !important;
    // background-color: #D42177 !important;
}

.ant-radio-button-wrapper {
    border-radius: 0px 2px 2px 0px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    background: var(--Neutral-1, #FFF);

    span::before {

        color: var(--3, #D42177) !important;
    }
}

.ant-select-selector {

    border: 1px solid var(--3, #D9D9D9) !important;

    span {
        color: var(--Neutrals-Gray, #555);
        /* Inputs/Text */
        font-family: Lato !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }

}


.ant-radio-button-wrapper:hover {
    color: var(--3, #D42177) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: var(--3, #D42177) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--3, #D42177) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #D42177;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    border-color: var(--3, #D42177) !important;
}

.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        color: var(--3, #D42177) !important;
        font-weight: 500;
        background-color: rgba(212, 33, 119, 0.1) !important;
        border: 1px solid var(--3, #D42177) !important;

        .ant-picker-calendar-date-value {
            color: var(--3, #D42177) !important;
        }
    }
}

.ant-picker-now-btn {
    color: var(--3, #D42177) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}


// .ant-picker-cell {
//     color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
//     text-align: right;

//     /* Body/regular */
//     font-family: Roboto;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 22px;
//     /* 157.143% */
// }


.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    border-radius: #E164A0 !important;
}

.ant-pagination .ant-pagination-item-active {
    border-color: var(--3, #D42177) !important;
}

.ant-pagination-item-active:hover,
.ant-pagination-item:hover {
    color: var(--3, #D42177) !important;
}

.ant-select-item-option-active,
.ant-radio-button-wrapper-disabled::before {
    background-color: var(--7, #FFF5FA) !important;
    // color: white !important;
}

.ant-select-item-option-selected {

    color: #000000D9 !important;
    background-color: rgba(212, 33, 119, 0.1) !important;
}

.ant-picker-calendar-header {
    transition: ease-in all 0, 5;
}



.ant-radio-button-wrapper {
    border-radius: 2px 0px 0px 2px;
    background: var(--Neutral-1, #FFF) !important;
    gap: -1px;

    span {
        font-family: Lato !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }
}

.ant-radio-button-wrapper-checked {
    border: 1px solid var(--3, #D42177) !important;

    span {
        color: var(--3, #D42177);
    }
}

.ant-picker-calendar-date-content {
    overflow-x: hidden;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            padding: 3px 10px;
            align-items: center;
            gap: 6px;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #999;
            background: #F6F6F6;

            .ant-badge {
                width: 100%;
                margin: 0 !important;
                padding: 0 !important;
                display: flex;
                align-items: center;

                .ant-badge-status-dot {
                    display: none !important;
                }

                .ant-badge-status-text {
                    padding: 0px;
                    margin: 0px;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: var(--Neutrals-Black, #3A3737);
                    font-family: Poppins;
                    font-size: 12px !important;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.12px;
                }
            }
        }
    }
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    border-color: var(--3, #D42177) !important;
}

.ant-input-outlined:focus-within {
    border-color: var(--3, #D42177) !important;
}

.ant-input-affix-wrapper:hover {
    border-color: var(--3, #D42177) !important;
}

.ant-input-affix-wrapper-focused {
    border-color: var(--3, #D42177) !important;
}

.ant-input:hover {
    border-color: var(--3, #D42177) !important;
}

.ant-input-focused {
    border-color: var(--3, #D42177) !important;
}

// /* Change the color of the entire suffix on hover */
// .ant-input-affix-wrapper:hover .ant-input-suffix {
//     color: var(--3, #A11F5C);
//     /* Darker and bolder version of #D42177 */
// }

// /* Change the color of the entire suffix on focus */
// .ant-input-affix-wrapper-focused .ant-input-suffix {
//     color: var(--3, #A11F5C);
//     /* Darker and bolder version of #D42177 */
// }

// /* Specifically target the clear icon on hover */
// .ant-input-affix-wrapper:hover .ant-input-clear-icon {
//     color: var(--3, #A11F5C);
//     /* Darker and bolder version of #D42177 */
// }

/* Specifically target the clear icon on focus */
// .ant-input-affix-wrapper-focused .ant-input-clear-icon {
//     color: var(--3, #A11F5C);
//     /* Darker and bolder version of #D42177 */
// }

/* Specifically target the custom search icon on hover */
// .ant-input-affix-wrapper:hover .ant-input-suffix img {
//     filter: invert(43%) sepia(71%) saturate(3175%) hue-rotate(313deg) brightness(90%) contrast(108%);
// }

// /* Specifically target the custom search icon on focus */
// .ant-input-affix-wrapper-focused .ant-input-suffix img {
//     filter: invert(43%) sepia(71%) saturate(3175%) hue-rotate(313deg) brightness(90%) contrast(108%);
// }


/* Change the default border color */
.ant-picker {

    border-radius: 4px;
    /* Optional: adjust border radius */
}

/* Change the border color when the calendar is focused */
.ant-picker-focused,
.ant-picker:hover,
.ant-picker-outlined:focus-within,
.ant-picker-input>input:focus {
    border-color: var(--3, #D42177)
        /* Same as focused state */
}

.ant-picker-range .ant-picker-active-bar,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: var(--3, #A11F5C);
}

.ant-picker-footer .ant-picker-ranges .ant-picker-ok .ant-btn:hover {
    background: var(--3, #faa6d0);
}

.ant-btn-primary {
    background: var(--3, #A11F5C);
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before {
    background: var(--7, #FFF5FA);
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
    background: var(--7, #FFF5FA);
}

.ant-table-wrapper .ant-table-tbody .ant-table-row-ant-table-row-selected>.ant-table-cell-row-hover {
    background: var(--7, #FFF5FA);
}


.form-item {
    label {
        flex: 1 0 0;
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */
        margin-bottom: 8px;
    }
}

.ant-form-item {
    label {
        flex: 1 0 0;
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));

        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */
        margin-bottom: 8px;
    }
}


// STATUSES

.noStatus {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: rgba(235, 211, 211, 0.777);

    span {
        color: rgba(172, 12, 12, 0.777);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.notAddressed,
.discussion {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--6, #FADCEA);

    span {
        color: var(--system-yellow-warning, #AA1A5F);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.documentReview {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--system-yellow-warning-400, #FFF9F0);

    span {
        color: var(--system-yellow-warning, #FC9403);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.ipClientReport,
.prePlanningPhase {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #DFE6FF;


    span {
        color: var(--Primary-Blueberry-700, #325899);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}


.dataCollection {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #EBF2FF;


    span {
        color: #3B81FE;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}


.closed {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--Neutrals-Gray-0, #E8E8E8);

    span {
        color: #555555;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.donorReport,
.planning {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--Neutrals-Gray-0, #ece7e2);

    span {
        color: #964B00;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}


.dataCheck,
.dataAnalysis,
.postDataCollection {

    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #DEF5F5;

    span {
        color: #045B46;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.completed,
.visitCompleted {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #E7F1E6;

    span {
        color: #0F7507;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.dataCleaning,
.fieldReportApproved {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--Graph-Green-1, #e1edee);
    ;

    span {
        color: var(--Graph-Green-1, #06768E);

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.fieldReportWriting {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #E7F1E6;
    ;

    span {
        color: var(--Range-assessment-Excellent, #3D9B4C);

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.fieldReportSubmitted {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: var(--Graph-Green-3, #e5f6fa);
    ;

    span {
        color: var(--Graph-Green-3, #6FC5D5);

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.fieldReportCompleted {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #f5f1f8;

    span {
        color: #9670b8;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.dataTranslation,
.translation {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #eeeee6;

    span {
        color: #918d27;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}


/////copied from reporting|
///
/// 
/// 

// .finalization {
//     width: fit-content;
//     display: flex;
//     padding: 3px 10px;
//     justify-content: center;
//     align-items: center;
//     gap: 6px;
//     border-radius: 16px;
//     background: #def5f5;

//     span {
//         color: #045b46;
//         font-family: Poppins;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//         letter-spacing: 0.12px;
//     }
// }

// .delivered {
//     width: fit-content;
//     display: flex;
//     padding: 3px 10px;
//     justify-content: center;
//     align-items: center;
//     gap: 6px;
//     border-radius: 16px;
//     background: #aba730;

//     span {
//         color: #0f7507;
//         font-family: Poppins;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//         letter-spacing: 0.12px;
//     }
// }



// .statusDiv {
//     display: flex;
//     padding: 3px 10px;
//     justify-content: center;
//     align-items: center;
//     gap: 6px;
//     border-radius: 16px;
//     background: #ebf2ff;

//     span {
//         color: #3b81fe;
//         font-family: Poppins;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: normal;
//         letter-spacing: 0.12px;
//     }
// }



.resourcesColumn {
    display: flex;
    // padding: 16px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    max-height: 60px !important;
    overflow-y: scroll;
    overflow-x: hidden;


    span {
        align-self: stretch;
        color: var(--Neutrals-Gray-400, #366dc7);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
        /* 17.28px */
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;

        cursor: pointer;

        &:hover {

            text-decoration-line: underline;
        }
    }

}