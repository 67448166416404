.siteVisitDetails {
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
}

.chart-card {
  border-radius: 16px;
  border: 1px solid var(--13, #e6e6e6);
  background: var(--Neutrals-White, #fff);
  box-shadow: 0px 2px 4px 0px rgba(19, 30, 69, 0.1);
  max-height: 500px;
  display: flex;
  padding: 50px 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.delays-card {
  display: flex;
  padding: 50px 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--13, #E6E6E6);
  background: var(--Neutrals-White, #FFF);

  /* Cards/General/Little cards */
  box-shadow: 0px 2px 4px 0px rgba(19, 30, 69, 0.10);

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;

    h1 {
      align-self: stretch;
      color: var(--2, #AA1A5F);

      /* Basic/Heading banners-large */
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      /* 150% */
    }

    h2 {
      align-self: stretch;
      color: var(--1, #6A113C);

      /* Basic/Subtitle-semibold */
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      /* 144.444% */
    }
  }

  img {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: var(--6, #FADCEA);
  }

}

.header-row {
  background: var(--7, #FFF5FA);
}




.calendarView {
  display: flex;
  padding: 50px 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Neutrals-White, #FFF);

  /* Cards/General/Little cards */
  box-shadow: 0px 2px 4px 0px rgba(19, 30, 69, 0.10);

  h2 {
    flex: 1 0 0;
    color: var(--1, #6A113C);

    /* Basic/Subtitle-semibold */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 144.444% */
  }

  table {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 184.615% */
    letter-spacing: -0.13px;
  }


  .calendarContainer {
    border: 2px solid var(--13, #E6E6E6);
    display: flex;
    flex-direction: column;
    gap: 0 !important;

    :where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
      border-start-start-radius: 0 !important;
    }

    :where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
      border-start-end-radius: 0 !important
    }

    :where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table {
      border-radius: 0 !important
    }

    .ant-table {
      overflow-x: scroll;
      border: none !important;
      text-wrap: nowrap;
    }

    th {
      border-right: none !important;
      background: var(--7, #FFF5FA) !important;
      height: 32px !important;
    }

    .ant-table-cell {
      border-right: 1px solid var(--13, #E6E6E6);
      border-bottom: 1px solid var(--13, #E6E6E6);
      background: var(--Neutrals-White, #FFF);
      text-align: center;
    }

    .ant-table-cell.dataCell {
      // width: 100%;
      height: 56px;
      text-align: center;
      border-right: 1px solid var(--13, #E6E6E6);
      border-bottom: 1px solid var(--13, #E6E6E6);
      background: var(--Neutrals-White, #FFF);
    }

    .ant-table-cell.dataCell.hasSiteVisit {
      &:hover {
        scale: 1.03;
      }
    }



    .tableHeader {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0;
      border-top: 1px solid var(--6, #FADCEA);
      border-bottom: 1px solid var(--6, #FADCEA);
      background: var(--7, #FFF5FA);
      color: var(--2, #AA1A5F);
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 184.615% */
      letter-spacing: -0.13px;

      .activity {
        display: flex;
        width: '94px';
        height: 60px;
        padding: 6px 10px;
        justify-content: start;
        align-items: center;
        gap: 4px;
      }

      .month {
        display: flex;
        height: 60px;
        padding: 6px 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
      }
    }
  }


}

.chart {
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    align-self: stretch;
  }
}



.cellWithData {
  display: flex;
  height: 96px;
  width: 104px;
  padding: 4px 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  text-align: start;


  .counter {
    display: flex;
    padding: 1px 10px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 16px;
    border: 1px solid var(--15, #FFF);
    background: var(--15, #FFF);

    /* Badges/Label-medium */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
  }

  a {
    height: 20px;
    flex-shrink: 0;
    align-self: stretch;
    color: var(--15, #FFF);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
    letter-spacing: -0.13px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cellsRightShadow {
  box-shadow: 5px 0px 4px rgba(0, 0, 0, 0.04);

}