.location-details {
  .ant-row {
    margin-top: 20px;
  }

  .ant-col {
    padding: 0 20px;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
  }

  .ant-switch {
    margin-right: 10px;
  }
}

.osm-search {
  height: 40px;

  .autocomplete {
    width: 100%;

    .autocomplete-form {
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      padding: 4px 11px;

      .autocomplete-input-wrapper {
        margin-left: 0;
        display: flex;
      }

      input {
        padding: 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.88);
      }

      .autocomplete-divider {
        height: 0;
      }

      button {
        padding: 0;
      }
    }
  }
}

.parish {
  flex: 1 0;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}