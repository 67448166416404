.budgetWrapper {
  padding: 40px 40px;
}

.budgetSummaryBody {
  display: flex;
  padding: 60px 80px 80px 80px;
  flex-direction: column;
  gap: 45px;
  align-self: stretch;
  border-radius: var(--Corners-corner-medium, 8px);
  background: var(--Greys-White, #fff);

  /* Elevation/Cards/Cards */
  box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
  h3 {
    color: var(--1, #6a113c);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 85.714% */
  }

  .bodyContent {
    display: flex;
    justify-content: space-between;
    .budgetButtons {
      display: flex;
      gap: 10px;
      .exportBtn {
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: var(--Corners-corner-medium, 8px);
        border-radius: var(--Corners-corner-medium, 8px);
        border: 1px solid var(--2, #aa1a5f);
        color: var(--2, #aa1a5f);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }

      .contractBtn {
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: var(--Corners-corner-medium, 8px);
        border-radius: var(--Corners-corner-medium, 8px);
        border: 1px solid var(--15, #fff);
        background: var(--2, #aa1a5f);

        /* Elevation/Buttons */
        box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
        color: var(--15, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
  }

  .contractSelect {
    label {
      padding-bottom: 10px;
    }
  }

  .contractStatistics {
    h4 {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
    }
    h3 {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
    }
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 16px;
    border: 1.5px solid var(--5, #f5b0d2);
    background: rgba(250, 220, 234, 0.35);
  }

  .staffInformation {
    h4 {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
    }
    h3 {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
    }
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 16px;
    border: 1.5px solid var(--5, #f5b0d2);
    background: rgba(250, 220, 234, 0.35);
  }
}

@media only screen and (min-width: 275px) and (max-width: 1240px) {
  .budgetSummaryBody {
    .bodyContent {
      display: flex;
      flex-direction: column;
    }
    .budgetButtons {
      display: flex;
      flex-direction: column;
    }
  }

  .bodyContent h3 {
    font-size: 18px;
  }

  .exportBtn,
  .contractBtn {
    width: 100%; /* Ensure buttons take full width */
    margin-bottom: 10px; /* Add space between buttons */
  }
}

.noDataToDisplay {
  display: flex;
  padding: 80px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px var(--Corners-corner-medium, 8px)
    var(--Corners-corner-medium, 8px);
  background: var(--Neutrals-White, #fff);
}

.mainContractDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  align-self: stretch;
  height: auto;
}

.contractDivs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  flex: 1;
  border-radius: 10px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
  height: auto;
}

@media only screen and (max-width: 768px) {
  .mainContractDiv {
    flex-direction: column;
  }
  .contractDivs {
    width: 100%; /* Full width for small screens */
  }
}

@media only screen and (min-width: 769px) {
  .mainContractDiv {
    flex-direction: row; /* Horizontal layout on larger screens */
  }
}

.numberDiv {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 104%; /* 62.4px */
}

.textDiv {
  color: var(--Greys-Mid-Gray, #999);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 144%; /* 23.04px */
}

@media only screen and (max-width: 768px) {
  .budgetSummaryBody {
    padding: 20px; /* Smaller padding for mobile */
  }
  .exportBtn,
  .contractBtn {
    width: 100%; /* Full width buttons on mobile */
  }
  .contractDivs {
    padding: 12px; /* Adjust padding for smaller screens */
  }
  .numberDiv {
    font-size: 40px; /* Smaller text for mobile */
  }
  .textDiv {
    font-size: 14px;
  }
}

.secondMainDiv {
  display: flex;
  width: 100%;
  padding: 20px 20px 26px 20px;
  align-items: center;
  gap: 28px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
}

.secondDateDiv {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: var(--6, #fadcea);
}

.secondNumberDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Corners-corner-medium, 8px);
  flex: 1 0 0;
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 50% */
}

.workingDays {
  color: var(--Greys-Mid-Gray, #999);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.mainStaffDiv {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: var(--Corners-corner-medium, 8px);
  border: 1px solid var(--5, #f5b0d2);
}

.staffDiv {
  display: flex;
  height: 110px;
  padding: 20px 20px 26px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
}

.groupDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

@media only screen and (max-width: 768px) {
  .groupDiv {
    flex-direction: column;
  }
}

.summary {
  display: flex;
  width: 100%;
  padding: 20px 0px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 9px;
  margin-top: 24px;
  border: 1px solid var(--5, #f5b0d2);
}

.summaryGroup {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 15px;
  align-self: stretch;
  flex-wrap: wrap;
}

.frame {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 calc(25% - 15px);
}

.frameText1 {
  color: var(--11, #999);

  /* Inputs/Label */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.frameNumber1 {
  color: var(--10, #737373);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 150% */
}

.frameNumber2 {
  color: var(--2, #aa1a5f);

  /* Basic/Heading banners-large */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
}
.frameNumber3 {
  color: var(--Range-assessment-Very-good, #8bb706);

  /* Basic/Heading banners-large */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
}

.saveDraftButton {
  display: flex;
height: 44px;
padding: 4px 16px;
justify-content: center;
align-items: center;
gap: var(--Corners-corner-medium, 8px);
border-radius: var(--Corners-corner-medium, 8px);
background: var(--3, #D42177);
color: var(--character-primary-inverse, #FFF);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 157.143% */
box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
}

.saveDraftButton:hover {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: var(--Corners-corner-medium, 8px);
  border-radius: var(--Corners-corner-medium, 8px) !important;
  background: var(--3, #D42177) !important;
  color: var(--character-primary-inverse, #FFF) !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10) !important;
}

.contractBudget {
  font-size: 28px;
  line-height: 24px;
  font-weight: 600;
  color: #6A113C;
  margin-bottom: 0;
  margin-top: 20px;
}

.ant-select {
  .ant-select-arrow-loading {
    color: var(--3, #D42177) !important;
  }
}