.planning-page {
  padding-top: 45px;

  .ant-tabs-nav-wrap {
    padding-left: 25px;
  }

  .ip-planning-wrapper {

    .tab-container {
      padding-left: 0 !important;
    }
  }

  .ant-tabs-tab-btn {
    color: var(--9, #404040);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 133.333% */
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      background-color: transparent;
      border: none;
    }

    .ant-tabs-tab-active {
      border-radius: 6px 6px 0px 0px;
      border-top: 2px solid var(--6, #fadcea);
      border-right: 2px solid var(--6, #fadcea);
      border-left: 2px solid var(--6, #fadcea);
      background: var(--Neutrals-White, #fff);
    }

    .custom-tab-label {
      position: relative;
      display: inline-block;

      .draft-count {
        position: absolute;
        border: 1px solid #AA1A5F;
        background-color: #AA1A5F;
        border-radius: 50%;
        background-color: white;
        background: #AA1A5F;
        top: -8px;
        right: -22px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }





    .ant-tabs-nav-list {
      .ant-tabs-tab-btn {}
    }

    .ant-tabs-tab-btn {
      color: var(#404040);
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      /* 133.333% */
      .ant-tabs-tab-active {
        border-radius: 6px 6px 0px 0px;
        border-top: 2px solid var(--6, #fadcea);
        border-right: 2px solid var(--6, #fadcea);
        border-left: 2px solid var(--6, #fadcea);
        background: var(--Neutrals-White, #fff);
      }
    }

    .ant-tabs-tab {
      color: #aa1a5f;
    }

    .ant-tabs-ink-bar {
      height: 2px;
      background: #aa1a5f !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #6a113c;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 133.333% */
    }
  }
}

//IP Planning Board

.ip-planning-wrapper {
  padding: 45px 40px;

  .text-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .ip-planning-buttons {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .export-selectedBtn {
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border-radius: 8px;
        background: #aa1a5f;

        /* Elevation/Buttons */
        box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
        color: var(--character-primary-inverse, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }

      .add-new-ipBtn {
        display: flex;
        height: 44px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--1, #6a113c);

        /* Elevation/Buttons */
        box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
        color: var(--character-primary-inverse, #fff);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }
    }

    h3 {
      color: var(--2, #aa1a5f);

      /* Basic/Title */
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      /* 145.455% */
    }
  }

  .tab-container {
    .tableviewTabs {
      width: 100%;

      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab-active {
              border: none !important;
            }
          }
        }
      }
    }

    .ant-tabs-nav-wrap {
      padding: 0;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #aa1a5f;
    }
  }
}

.ant-table-wrapper .ant-table-thead {
  tr {
    th {
      color: var(--2, #aa1a5f);
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 184.615% */
      letter-spacing: -0.13px;
    }
  }
}


.cardInputs,
.filterCardInput {
  width: 100% !important;
  max-width: 400px;
  margin-bottom: 20px !important;
  box-sizing: border-box;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {

  .cardInputs,
  .filterCardInput {
    width: 100% !important;
    max-width: 100% !important;
  }
}