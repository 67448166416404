.reportingListDiv {
  padding: 60px 40px 80px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  background: var(--Neutrals-White, #fff);
}

.listDesign {
  border-radius: 8px;
  border: 1px solid var(--13, #e6e6e6);
  background: rgba(246, 246, 246, 0.16);
  display: flex;
  width: 100%;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.titleText {
  color: var(--1, #6a113c) !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.buttonsList,
.buttonsList:hover {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-Gray-200, #c1c1c1) !important;
}

.btnSeeSiteDetails {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: var(--2, #aa1a5f);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  border-radius: 6px;
  background: var(--6, #fadcea);

  /* Elevation/Buttons */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);

  &:hover {
    color: var(--2, #aa1a5f) !important;
    background: var(--6, #fadcea) !important;
    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
    border: 1px solid var(--2, #aa1a5f) !important;
  }
}

.uploadDivStyle {

  .ant-row,
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-col,
  .ant-form-item-label {
    display: flex;
  }
}

.SiteVisitApproval {
  display: inline-flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--3, #D42177);

  /* Elevation/Buttons */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
  color: var(--character-primary-inverse, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */

}