.uploadIpList {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--1, #6a113c);
    background: var(--7, #fff5fa);
    color: var(--1, #6a113c);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    margin-bottom: 20px;
  }
  
  .divIpDesign {
      display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 32px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--6, #FADCEA);
    background: var(--7, #FFF5FA);
  
    .ipGroupWrapper {
      border-bottom: 1px solid #FADCEA;
      padding-bottom: 15px;
  
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  
  .ipGroup {
    .travel_rate {
      margin-bottom: 25px;
    }
    .travel_rate, .acco_rate {
      width: 100%;
      padding: 20px;
      border-radius: 16px;
      border: 1px solid #FADCEA;
  
      h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #3A3737;
  
        span {
          font-size: 12px;
          line-height: 22px;
          font-weight: 400;
          color: #979797;
        }
      }
  
      .ant-form-item {
        margin-bottom: 0;
      }
  
      .ant-checkbox-wrapper {
        padding: 0;
        margin-bottom: 0;
        width: 100%;
  
        .checkboxEnable {
          width: 100%;
          background-color: #FADCEA75;
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 15px;
          display: flex;
          gap: 10px;
          align-items: center;
          transition: .2s;
          cursor: pointer;
    
          .checkboxIcon {
            position: relative;
            min-width: 20px;
            min-height: 20px;
            max-width: 20px;
            max-height: 20px;
            border-radius: 20px;
            background-color: #fff;
            border: 1px solid #D42177;
  
            .checkboxIconInner {
              position: absolute;
              width: 0;
              height: 0;
              border-radius: 50%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background-color: #D42177;
              transition: .2s;
            }
          }
    
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #D42177;
          }
  
          &:hover {
            background-color: #FADCEA;
            border: 1px solid #D42177;
          }
        }
  
        &:has(.ant-checkbox-checked) .checkboxEnable {
          background-color: #FADCEA;
          border: 1px solid #D42177;
  
          .checkboxIcon {
            .checkboxIconInner {
              width: 10px;
              height: 10px;
            }
          }
        }
  
        .ant-checkbox {
          display: none;
        }
  
        span {
          padding: 0;
          width: 100%;
        }
      }
  
      .travelRateOn, .accomodationRateOn {
        background-color: #FADCEA75;
        border-radius: 8px;
        border: 1px px solid #FADCEA75;
        padding: 20px;
        margin-top: 15px;
      }
    }
  }
  
  thead{
    .ant-table-cell-fix-right-first {
      filter: drop-shadow(-1px 0px 5px #9f9e9e);
    }
  }
  