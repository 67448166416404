.banner-container {
    background: rgba(250, 220, 234, 0.50);
    height: 200px;
    width: 100%;
    padding: 40px 0px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .banner-text {
    color: var(--2, #AA1A5F);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%; /* 46.08px */
  }
 
  
  @media (max-width: 900px) {
    .banner-container {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }
    
    .banner-text {
      margin-bottom: 20px;
      text-align: center;
    }
  
    .banner-photo {
      max-width: 100%;
    }
  }

  @media (max-width: 379px) {
    .banner-photo {
       display: none;
    }
  }
