.visitsMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  height: 500px;
}

.visitsMapContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.left {
  flex: 1;
  padding: 10px;
  height: 100%;
}

.right {
  flex: 2;
  padding: 10px;
}

.resourcesText,
.resourcesText:hover {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  text-decoration-line: underline;
}

.dataCollectionMain {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  .ant-input-disabled {
    color: var(--10, #737373); /* Custom color */
    background-color: rgba(0, 0, 0, 0.04); /* Background color */
    border-color: #d9d9d9; /* Border color */
    box-shadow: none; /* Remove box shadow */
    opacity: 1; /* Ensure opacity is not reduced */
    font-family: Roboto, sans-serif; /* Set the font family */
    font-size: 16px; /* Set the font size */
    font-style: normal; /* Set the font style */
    font-weight: 400; /* Set the font weight */
    line-height: 22px; /* Set the line height */
    cursor: default; /* Remove "not-allowed" cursor and set to default */
  }
}

.dataCollectionFormInput {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-3, #f5f5f5);
}

.dataCollectionFormInput1 {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-3, #f5f5f5);
}

.dataCollectionButton,
.dataCollectionButton:hover {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: var(--6, #fadcea) !important;
  color: var(--2, #aa1a5f) !important;
  border-color: var(--2, #aa1a5f) !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  /* Elevation/Buttons */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
}
.dataCollectionPreviewMainDiv {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--5, #f5b0d2);
  background: var(--7, #fff5fa);
}

.dataCollectionPreviewTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--1, #6a113c);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dataCollectionLinkButton {
  display: flex;
width: 44px;
height: 44px;
padding: 4px 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
border: 1px solid var(--Neutrals-Gray-200, #C1C1C1);
}

