.wrapper {
  background: #fff5fa;
  margin: 0;
  padding: 0;
}

.left-column {
  .leftImage {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
}

.right-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .form-wrapper {
    display: flex;
    width: 80% !important;
    padding: 80px 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(106, 17, 60, 0.25);
  }

  p {
    color: var(--greys-50-b, #808080);
    font-family: "Source Sans 3";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 29.7px */
  }

  h2 {
    color: #d42177;
    /* H4 - 36 */
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 45px */
    letter-spacing: 0.36px;
  }

  label {
    color: var(--Neutrals-Black, #3a3737);
    /* Inputs/Label */
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }

  button {
    display: flex;
    width: 100%;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Greys-White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    border-radius: 8px;
    background: #d42177;
    border-style: none;
    box-shadow: 0px 3px 5px 0px rgba(128, 128, 128, 0.35);
  }

  .form-check {
    display: flex;
    gap: 5px;
  }
}

.row {
  --bs-gutter-x: 0;
}

.forgotPassword {
  color: var(--3, #D42177) !important;
  text-align: center;
  /* Inputs/Message */
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 183.333% */
  cursor: pointer;
}

@media (max-width: 1024px) {
  .leftImage {
    height: 50vh;
  }
}

@media (max-width: 767px) {
  .left-column {
    display: none;
  }
  .right-column {
    justify-content: center;
    align-items: center;

    .form-wrapper {
      width: 100%;
      padding: 40px 20px;
    }
  }
}

@media (max-width: 768px) {
  .right-column {
    .form-wrapper {
      width: 90%;
      padding: 40px 20px;
      gap: 16px;
    }

    p {
      font-size: 18px;
    }

    h2 {
      font-size: 28px;
    }

    label {
      font-size: 10px !important;
    }

    button {
      padding: 8px 20px;
      font-size: 12px;
    }

    .forgotPassword {
      font-size: 10px !important;
    }
  }
}

@media (max-width: 480px) {
  .right-column {
    .form-wrapper {
      padding: 20px !important;
    }
  }
}

@media (max-width: 480px) {
  .right-column {
    .form-wrapper {
      width: 100%;
      padding: 20px 10px;
      gap: 12px;
    }

    p {
      font-size: 16px;
    }

    h2 {
      font-size: 24px;
    }

    label {
      font-size: 8px !important;
    }

    button {
      padding: 6px 16px;
      font-size: 10px;
    }

    .forgotPassword {
      font-size: 8px !important;
    }
  }
}
.wrapper .right-column .form-wrapper {
  display: flex;
  width: 100%;
  padding: 80px 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(106, 17, 60, 0.25);
}