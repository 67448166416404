.accountSettings {
    display: flex;
    padding: 60px 80px 80px 80px;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 45px;
    border-radius: 8px;
    background: var(--Greys-White, #FFF);
    width: 80%;
    box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
    margin: auto; 
 

  .title {
    color: var(--1, #6A113C);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 85.714% */
  }

  h4 {
    color: var(--2, #AA1A5F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
}
.loginInformation {
    display: flex;
margin-top: 20px;
padding: 0px 40px;
flex-direction: column;
align-items: center;
justify-content: center;
}