.questionType {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    margin-bottom: 24px;
    border-radius: 8px;
    border: 1px solid var(--6, #fadcea);
    margin: 32px 0;

    .questionTypeHeader {
        display: flex;
        padding: 20px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 1px solid var(--6, #fadcea);
        background: var(--7, #fff5fa);

        div {
            width: 100%;

            .ant-radio-wrapper {
                padding: 12px;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 8px;

                .ant-radio-inner {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                }
            }

            .ant-radio-wrapper.type-radio {
                font-family: Lato !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.32px;

                .ant-radio-inner {
                    border: 1px solid var(--2, #aa1a5f);
                }

                .ant-radio-inner::after {
                    background-color: var(--2, #aa1a5f);
                    content: "";
                    /* Ensure the pseudo-element is visible */
                    display: block;
                    width: 12px;
                    /* Increase the width as needed */
                    height: 12px;
                    /* Increase the height as needed */
                    border-radius: 50%;
                    /* Ensure it's circular */
                    position: absolute;
                    /* Position it absolutely */
                    top: 95%;
                    /* Center it vertically */
                    left: 95%;
                    /* Center it horizontally */
                    transform: translate(-50%, -50%);
                    /* Adjust positioning */
                }
            }

            .ant-radio-wrapper-checked.type-radio {
                background: var(--system-red-error-400, #fdeff0);
                border-radius: 8px;
                background: var(--6, #fadcea);
                color: var(--2, #aa1a5f);
            }

            .ant-radio-wrapper.yes-radio {
                border: 1px solid var(--system-red-error, #e31a32);
                /* Basic/Body */
                font-family: Lato !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.32px;

                .ant-radio-inner {
                    border: 1px solid var(--system-red-error, #e31a32);
                }

                .ant-radio-inner::after {
                    background-color: #e31a32;
                }
            }

            .ant-radio-wrapper-checked.yes-radio {
                background: var(--system-red-error-400, #fdeff0);
                color: var(--2, #aa1a5f);
            }

            .ant-radio-wrapper.no-radio {
                border: 1px solid var(--system-green-sucess, #0e9349);
                /* Basic/Body */
                font-family: Lato !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
                letter-spacing: 0.32px;

                .ant-radio-inner {
                    border: 1px solid var(--system-red-error, #0e9349);
                }

                .ant-radio-inner::after {
                    background-color: #0e9349;
                }
            }
        }
    }

    .questionTypeBody2.hidden,
    .questionTypeBody1.hidden {
        display: none !important;
    }

    .questionTypeBody1 {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;

        .ant-form-item {
            width: 100%;
        }
    }

    .questionTypeBody2 {
        padding: 0px 16px;

        .ant-form-item-control-input {
            display: flex;
            height: 81px;
            padding: 22.5px 98px 22.5px 130px;
            justify-content: flex-end;
            align-items: center;
            align-self: stretch;
        }
    }

    .questionTypeBody3 {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        text-wrap: nowrap !important;

        .ant-radio-group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 12px;
        }

        .ant-radio-wrapper {
            flex: 0 0 48%;
            display: flex;
            align-items: center;
        }



        .ant-form-item {
            width: 100%;
        }

        .ant-form-item-required {
            margin-bottom: 10px !important;
        }
    }


    .ant-radio-wrapper {
        padding: 12px;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 8px;

        .ant-radio-inner {
            width: 20px;
            height: 20px;
            background-color: transparent;
        }
    }

    .ant-radio-wrapper.yes-radio {
        border: 1px solid var(--system-red-error, #e31a32);

        .ant-radio-inner {
            border: 1px solid var(--system-red-error, #e31a32);
        }

        .ant-radio-inner::after {
            background-color: #e31a32;
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper-checked.yes-radio {
        background: var(--system-red-error-400, #fdeff0);
    }

    .ant-radio-wrapper.no-radio {
        border: 1px solid var(--system-green-sucess, #0e9349);

        .ant-radio-inner {
            border: 1px solid var(--system-red-error, #0e9349);
        }

        .ant-radio-inner::after {
            background-color: #0e9349;
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper.challenging-radio {
        border: 1px solid var(--Graphs-1, #f55e5e);

        .ant-radio-inner {
            border: 1px solid var(--Graphs-1, #f55e5e);
        }

        .ant-radio-inner::after {
            background-color: var(--Graphs-1, #f55e5e);
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper.critical-radio {
        border: 1px solid var(--Graphs-1, #e87431);

        .ant-radio-inner {
            border: 1px solid var(--Graphs-1, #e87431);
        }

        .ant-radio-inner::after {
            background-color: var(--Graphs-1, #e87431);
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper.semiAccessible-radio {
        border: 1px solid var(--Graphs-1, #ffaa00);

        .ant-radio-inner {
            border: 1px solid var(--Graphs-1, #ffaa00);
        }

        .ant-radio-inner::after {
            background-color: var(--Graphs-1, #ffaa00);
            left: 3px;
            top: 3px;
            width: 28px;
            height: 28px;
        }
    }

    .ant-radio-wrapper-checked.no-radio {
        background: var(--system-green-sucess-400, #eef7f2);
    }

    .questionInfo {
        color: var(--Neutrals-Black, #3a3737);
        flex: 1 0 0;
        /* Inputs/Label */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 137.5% */

        span {
            display: block;

            color: var(--Neutrals-Gray-300, #979797);

            /* Inputs/Text */
            font-family: Lato !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }
    }

    .ant-slider-step,
    .ant-slider-mark {
        color: var(--Neutral-3, #f5f5f5) !important;
        border-radius: 100px;
        background: var(--Neutral-3, #f5f5f5) !important;

        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
        /* 20.16px */
        letter-spacing: 0.14px;

        :nth-child(1) {
            color: #e31a32;
        }

        :nth-child(2) {
            color: #e31a32;
        }

        :nth-child(3) {
            color: #e8af11;
        }

        :nth-child(4) {
            color: #3d9b4c;
        }

        :nth-child(5) {
            color: #3d9b4c;
        }
    }

    .ant-slider-dot {
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 17px;
        border: 2px solid var(--Neutrals-Gray-300, #979797) !important;
        background: var(--Neutral-1, #fff);
    }

    .ant-radio-group {
        label {
            /* Basic/Body */
            font-family: Lato !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.32px;
        }
    }
}

.ant-radio-group {
    .ant-radio-wrapper {
        &.no-radio {
            color: #3d9b4c;
        }

        &.challenging-radio {
            color: var(--Graphs-1, #f55e5e);
        }

        &.critical-radio {
            color: var(--Graphs-3, #e87431);
        }

        &.semiAccessible-radio {
            color: var(--Graphs-4, #ffaa00);
        }

        &.yes-radio {
            color: #e31a32;
        }
    }
}