.projectName {
  display: flex;
  flex-direction: column;
  gap: 23px;
  background: #fadcea;
  padding: 40px 32px 36px 32px;
  width: 100%;
}

.projectDashboardButton,
.projectDashboardButton:hover {
  width: 44px;
  height: 44px;
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid var(--2, #aa1a5f) !important;
  background: var(--7, #fff5fa) !important;
  margin-left: 10px;
}

.projectNameText {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

.projectNameDescription {
  color: var(--9, #404040);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 80%;
  white-space: normal;
  /* Allow text to wrap */
  display: block;
  /* Standard block display for paragraph */
  overflow-wrap: break-word;
  /* Ensure long words wrap inside the box */
}


.projectTags {
  display: flex;
  flex-wrap: wrap;
  /* Allow tags to wrap */
  gap: 13px;
}

.projectTagDesign {
  display: flex;
  height: 26px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 30px;
  border: 1px solid var(--4, #e164a0);
  background: var(--7, #fff5fa);
  color: var(--3, #d42177);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.24px;
}

.actionsDiv {
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  border: 1.5px solid var(--6, #fadcea);
  background: #fff;
}

.quickActions {
  display: flex;
  flex-wrap: wrap;
  /* Allow quick actions to wrap */
  justify-content: center;
  gap: 25px;
}

.quickActionsButton,
.quickActionsButton:hover {
  display: flex;
  height: 52px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--6, #fadcea) !important;
  background: var(--7, #fff5fa) !important;
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
  color: var(--2, #aa1a5f) !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 768px) {
  .projectName {
    padding: 20px;
  }

  .projectTags {
    flex-direction: column;
    /* Change to column layout */
  }

  .quickActions {
    flex-direction: column;
    /* Change to column layout */
    align-items: center;
    /* Center items */
  }
}

.tab-container {
  display: flex;
  border-top: none !important;
  background: unset !important;
  padding-left: 20px;
  // border-bottom: 2px solid #fadcea !important;

  .tab-nav-active {
    padding: 12px 16px !important;
    align-items: center !important;
    gap: 6px !important;
    cursor: pointer !important;
    background-color: var(--Blue-purple-Purple-4, #fff) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--1, #6a113c) !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    // margin-top: -10px;
    border: 2px solid #fadcea;
    border-bottom: none;
    // margin-bottom: -2px !important;
  }

  .tab-nav-not-active {
    padding: 12px 16px !important;
    align-items: center !important;
    gap: 6px !important;
    border: none !important;
    // background-color: var(--Greys-White, #fff) !important;
    background-color: unset !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--9, #404040) !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    /* 122.222% */
    border-bottom: none;
  }

  label.tab-nav-active,
  label.tab-nav-not-active {
    // margin-bottom: -2px !important;
    cursor: pointer;
    // width: 150px;
    // text-align: center;
  }
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  /* Remove the default focus outline */
}

/* Style the custom radio button */
input[type="radio"]:checked {
  /* Add your styles for the checked state here, if needed */
}


.ant-spin {
  .ant-spin-dot-item {
    background-color: #aa1a5f;
  }
}


.VisitDataSummaryContainer {
  display: flex;
  width: 1158px;
  padding: 60px 40px 80px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  background: var(--Neutrals-White, #FFF);

  .containerOfVisits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    align-self: stretch;

    .siteVisit {
      .visable {
        border-radius: 16px 16px 0 0 !important;

      }

      .siteVisitTitle {
        cursor: pointer;
        display: flex;
        padding: 20px 24px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        background: var(--6, #FADCEA);



        p {
          flex: 1 0 0;
          color: var(--1, #6A113C);
          margin: 0 !important;
          padding: 0 !important;
          /* Basic/Subtitle-semibold */
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          /* 144.444% */
        }

        svg {
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }

        .siteVisitDetails {
          display: flex;
          padding-bottom: 40px;
          flex-direction: column;
          align-items: center;
          gap: 32px;
          align-self: stretch;
          border-radius: 16px;
          border: 1px solid var(--6, #FADCEA);
          background: var(--7, #FFF5FA);

          .chartsContainer {
            display: flex;
            padding: 0px 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;

            align-self: stretch;
            border-radius: 16px;
            border: 1px solid var(--6, #FADCEA);
            background: var(--7, #FFF5FA);


          }
        }
      }
    }
  }
}