//ip-planning tableview

.filter-container {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 24px;
  border-radius: 8px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: rgba(246, 246, 246, 0.4);
  .search-filters {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .ant-input-search {
      flex: 1;
      margin-right: 20px;
    }
    .ant-input-suffix {
      display: none !important;
    }
    span {
      display: flex !important;
      flex-direction: row-reverse !important;
      max-width: 550px;
    }
    .filter-btn {
      display: flex !important;
      gap: 20px !important;

      .ant-btn {
        display: flex;
        border: none;
        background: none;
        box-shadow: none;
      }
      .clear-allBTN {
        color: var(--9, #404040);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        border: none;
      }
      .hide-filters-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--2, #aa1a5f);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .search-section {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    h5 {
      color: var(--Neutrals-Black, #3a3737);

      /* Inputs/Label filters */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
}

@media (max-width: 768px) {
  .search-filters {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    .ant-input-search {
      margin-right: 0;
      margin-bottom: 20px; /* Provide spacing on smaller screens */
    }
  }
}
