.ant-modal.modal-deleted-tasks {}

.textHeadingTask {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 157.143% */
}

.textParagraphTask {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 122.222% */
}

.status-badge {
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.status-assigned {
  border-radius: 16px;
  background: var(--system-yellow-warning-400, #fff9f0);
  color: var(--system-yellow-warning, #fc9403);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}

.status-accepted {
  border-radius: 16px;
  background: #ebf2ff;
  color: #3b81fe;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}

.status-completed {
  border-radius: 16px;
  background: var(--system-green-sucess-400, #eef7f2);
  color: var(--system-green-sucess, #0e9349);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}

.status-on-hold {
  border-radius: 16px;
  background: linear-gradient(0deg, #fbf0e5 0%, #fbf0e5 100%), #d46600;
  color: #d46600;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}

.status-archived {
  border-radius: 16px;
  background: var(--Neutrals-Gray-0, #e8e8e8);
  color: var(--Neutrals-Gray, #555);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
}

.userListContainer {
  padding: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--6, #FADCEA);
  background: var(--7, #FFF5FA);

  .ant-checkbox-group {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    label {
      color: var(--Neutrals-Black, #3A3737);
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      flex-basis: 40%;
    }
  }

}