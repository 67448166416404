.calendar-wrapper {



    thead {
        tr {

            background-color: white !important;
            border: none !important;
            color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
            text-align: right;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
        }
    }


    .ant-picker-cell-selected::before {
        color: var(--3, #D42177) !important;
        background-color: #D42177 !important;
    }

    .ant-radio-button-wrapper {
        border-radius: 0px 2px 2px 0px;
        border: 1px solid var(--Neutral-5, #D9D9D9);
        background: var(--Neutral-1, #FFF);

        span::before {

            color: var(--3, #D42177) !important;
        }
    }

    .ant-select-selector {

        border: 1px solid var(--3, #D9D9D9) !important;

        span {
            color: var(--Neutrals-Gray, #555);
            /* Inputs/Text */
            font-family: Lato !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }

    }

    .ant-select-selector:hover {
        span {

            color: var(--3, #D42177) !important;
        }

    }

    .ant-radio-button-wrapper:hover {
        color: var(--3, #D42177) !important;
    }

   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: var(--3, #D42177) !important;
    }

   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: var(--3, #D42177) !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: #D42177;
    }

    .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
        border-color: var(--3, #D42177) !important;
    }

    .ant-picker-cell-selected {
        .ant-picker-cell-inner {
            color: var(--3, #D42177) !important;
            background-color: rgba(212, 33, 119, 0.1) !important;

            .ant-picker-calendar-date-value {
                color: var(--3, #D42177) !important;
            }
        }
    }



    .ant-picker-cell {
        color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
        text-align: right;

        /* Body/regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }


    .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
        border-radius: #E164A0 !important;
    }

    .ant-select-item-option-active,
    .ant-radio-button-wrapper-disabled::before {
        background-color: #D42177 !important;
        color: white !important;
    }

    .ant-select-item-option-selected {

        color: #000000D9 !important;
        background-color: rgba(212, 33, 119, 0.1) !important;
    }

    .ant-picker-calendar-header {
        transition: ease-in all 0, 5;
    }



    .ant-radio-button-wrapper {
        border-radius: 2px 0px 0px 2px;
        background: var(--Neutral-1, #FFF) !important;
        gap: -1px;

        span {
            font-family: Lato !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }
    }

    .ant-radio-button-wrapper-checked {
        border: 1px solid var(--3, #D42177) !important;

        span {
            color: var(--3, #D42177);
        }
    }

    .ant-picker-calendar-date-content {
        overflow-x: hidden;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                padding: 3px 10px;
                align-items: center;
                gap: 6px;
                width: 100%;
                border-radius: 4px;
                border: 1px solid #999;
                background: #F6F6F6;

                .ant-badge {
                    width: 100%;
                    margin: 0 !important;
                    padding: 0 !important;
                    display: flex;
                    align-items: center;

                    .ant-badge-status-dot {
                        display: none !important;
                    }

                    .ant-badge-status-text {
                        padding: 0px;
                        margin: 0px;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: var(--Neutrals-Black, #3A3737);
                        font-family: Poppins;
                        font-size: 12px !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.12px;
                    }
                }
            }
        }
    }




    @media (max-width: 992px) {
       .ant-picker-calendar .ant-picker-calendar-header {
            display: flex !important;
            flex-direction: row !important;
            justify-content: center !important;
            width: 100%;
            gap: 10px;
        }
    }

    // Mobile view
    @media (max-width: 768px) {
        .ant-picker-body {
            overflow-x: scroll !important;
            -webkit-overflow-scrolling: touch;

            table {
                table-layout: fixed;

                td,
                th {
                    ul {

                        margin: 2px 0px !important;
                    }

                    width: 100px !important;
                }
            }
        }

      .ant-picker-calendar .ant-picker-calendar-header {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            width: 100%;
            gap: 10px;

            .nt-radio-group,
            .ant-select {
                label {

                    width: 100% !important;
                }

                width: 100% !important;
                padding: 0 !important;
                margin: 0 !important;
            }
        }
    }
}