.mainDivUserManagement {
  display: flex;
  width: 100%;
  padding: 40px 40px 10px 0px;
  flex-direction: column;
  gap: 24px;
  background: var(--15, #fff);
}

.userManagementHeader {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  align-self: stretch;
  flex-wrap: wrap;
}

.userManagementHeaderFirst {
  display: flex;
  justify-content: flex-start;
  /* Align items to the start of the container */
  align-items: center;
  align-self: stretch;
  gap: 20px;
  flex: 1;
  /* Occupy remaining space */
  flex-wrap: wrap;
}

.userManagementHeaderSecond {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {

  .userManagementHeaderFirst,
  .userManagementHeaderSecond {
    width: 100%;
    /* Full width on smaller screens */
    justify-content: center;
    /* Center items horizontally */
  }
}

.userManagementHeaderText {
  color: var(--9, #404040);

  /* Basic/Title */
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 145.455% */
}

.inviteButtonTPM {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--2, #aa1a5f);
  color: var(--2, #aa1a5f);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.inviteButtonTPM:hover {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--2, #aa1a5f) !important;
  color: var(--2, #aa1a5f) !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.addButtonTPM {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--2, #aa1a5f);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
}

.addButtonTPM:hover {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #aa1a5f !important;
  background: var(--2, #aa1a5f) !important;
  color: var(--character-primary-inverse, #fff) !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
}

.membersText {
  color: var(--Neutrals-Gray, #555);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
}

.filtersWrapper {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: rgba(246, 246, 246, 0.4);
  margin-bottom: 15px;

  .filterSelect {
    :global .ant-select-selector {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: 180px !important;
      border-radius: 8px;
    }
  }

  .filterButton {
    display: flex;
    margin-left: 10px;
    height: 41px;
    padding: 12px 18px;
    align-items: center;
    border-radius: 28px;
    background: var(--blue-purple-purple-1,
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #5770cb);
    color: var(--grey-white, #fff);
    font-family: Poppins;
  }

  .filterButton:hover {
    color: var(--grey-white, #fff);
  }
}

.containerFilters {
  display: flex;
  gap: 20px;
  width: 100%;
}

.toggleButton,
.toggleButton:hover {
  color: var(--2, #aa1a5f) !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.clearButton,
.clearButton:hover {
  color: #404040 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  border: none !important;
  background-color: transparent;
  box-shadow: none !important;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 500px) {
  .containerFilters {
    flex-direction: column;
  }

  .filtersWrapper {
    margin-top: 20px;
  }

  .filterSelect {
    width: auto;
  }
}

.filterText {
  color: var(--Neutrals-Black, #3a3737);

  /* Inputs/Label filters */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.styledTitle {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 184.615% */
}

.styledDataIndex {
  color: var(--greys-dark-gray-25, #404040);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.styledDataIndex1 {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 144%;
  /* 17.28px */
}

.styledDataIndex2 {
  color: var(--Neutrals-Gray-300, #979797);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 144%;
  /* 17.28px */
}

.circleName {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 100px;
  border-bottom: 1px solid var(--13, #e6e6e6);
  background: #e6e6e6;
  text-align: center;
}

.circleLetter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--1, #6a113c);
  text-align: center;

  /* H5/regular */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.rowName {
  display: flex;
  height: 56px;
  padding: 16px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  min-width: 300px;
}

.ant-table {
  overflow-x: auto;
  border: 1px solid var(--6, #fadcea);

  tr {
    th {
      background-color: #fadcea !important;
      color: "#5770CB";
    }

    td {
      max-height: 10px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td:last-child {
      box-shadow: 0 0 5px 0 #fadcea;
    }
  }
}

.ant-table-tbody {
  tr {
    :nth-child(3) {
      text-align: start;
    }

    .ant-table-cell {}
  }
}

.ant-table-thead>tr>td {
  background-color: #eef2ff !important;
}

.ant-table-tbody td {
  max-width: 200px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-table-tbody>tr {
  line-height: 5px;
}

h3 {
  color: black;
  margin-bottom: 3%;

  span {
    display: inline-flex;
    align-items: center;
    color: #a6a6a6;
    margin-left: 10px;
    gap: 4px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.styledDataIndex3 {
  color: var(--Neutrals-Gray-400, #777);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
}

.actionDiv {
  display: flex;
  height: 56px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  &.actionSide {
    height: auto;
    padding: 5px 10px;
  }
}


.numberOfMembers {
  color: var(--3, #d42177);
  margin-right: 10px;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  display: flex;
}

.buttonsModal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.modalHeader {
  display: block;
  width: 100%;
  text-align: center;
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 30px;
}

.memberInvitation {
  h5 {
    color: var(--2, #aa1a5f);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    /* 25.92px */
  }
}

.emailsInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  display: flex;
  padding: 24px 12px;
  gap: 4px;
  ;
  align-self: stretch;
  border-radius: 6px;
  background: var(--7, #FFF5FA);

  h5 {
    color: var(--Neutrals-Black, #3A3737);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
  }

  textarea {
    border-radius: 4px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    background: var(--Neutral-1, #FFF);

    padding: 5px;

    position: relative;


    &:focus {
      box-shadow: none;
    }
  }

  textarea::placeholder {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
  }

  textarea:hover {
    border-color: none !important;
  }


}

.details {
  h5 {
    color: var(--2, #AA1A5F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;
    /* 25.92px */
  }
}



.modalsHeader1 {
  color: var(--2, #aa1a5f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 144%;
  /* 25.92px */
}

.react-tel-input .form-control {
  width: 100% !important;
}

.rolesAndPremissions {
  display: flex;
  padding: 24px 12px;
  align-items: center;
  margin-bottom: 20px;
  gap: 32px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--14, #f6f6f6);
}

.addRolesAndPremissins {
  display: flex;
  height: 60px;
  padding: 10px 21px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 7px;
  border: 1px dashed var(--5, #f5b0d2);
  background: var(--7, #fff5fa);
  color: var(--2, #aa1a5f);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 144%;
  /* 20.16px */
  margin-bottom: 20px;
  cursor: pointer;
}

.inviteText {
  color: var(--9, #404040);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.ant-spin-dot-item {
  background-color: var(--1, #6a113c);
}

.ant-spin-dot {
  color: var(--2, #aa1a5f);
}

.userManagementWrapper {
  .ant-tabs-tab {
    color: #777777;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 160% */
    letter-spacing: -0.15px;
  }

  .ant-tabs-tab:hover {
    color: var(--2, #aa1a5f) !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--2, #aa1a5f) !important;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 160% */
    letter-spacing: -0.15px;
  }

  .ant-tabs-ink-bar-animated {
    color: var(--2, #aa1a5f) !important;

    border-bottom: 2px solid var(--2, #aa1a5f) !important;
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid var(--2, #aa1a5f) !important;
  }
}


.emailCount {
  color: var(--3, #D42177);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */


}


.sendInvitationsContainer {

  .cancelBtn {
    display: flex;
    height: 44px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Corners-corner-medium, 8px);
    border: 1px solid var(--1, #6A113C);
    background-color: transparent;
    color: var(--1, #6A113C);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

}

.sendInvitationsButtonTPM {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--Corners-corner-medium, 8px);
  background: var(--1, #6A113C);

  /* Elevation/Buttons */
  box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
}