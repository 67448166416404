.paymentInformation {
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;

  .currentSubscription {
    padding: 40px 30px;
    margin-top: 40px;
    border-radius: 8px;
    background: var(--7, #fff5fa);

    /* Elevation/Cards/Little cards */
    box-shadow: 0px 2px 4px 0px rgba(19, 30, 69, 0.1);

    h6 {
      color: #aa1a5f;
      font-size: 22px;
      padding-bottom: 15px;
    }

    .subscriptionPlanBoxes {
      .ant-row:nth-child(2) {
        flex-direction: column-reverse;

        @media only screen and (min-width: 1200px) {
          flex-direction: row-reverse;
        }
      }
      .subscriptionPlans {
        border-radius: 8px;
        border: 1px solid #aa1a5f;
        background: var(--Grey-White, #fff);
        text-align: center;
        box-shadow: 0px 4px 12px 0px #fadcea;
        transition: all 0.3s ease;
        font-family: "Poppins";

        .planTitle {
          display: flex;
          flex-direction: column;
          align-items: center;
          grid-gap: 10px;
          padding: 26px 20px;
          font-size: 24px;
          color: #aa1a5f;

          .months {
            border: 1px solid #aa1a5f;
            padding: 6px 8px 6px 8px;
            border-radius: 20px;
            width: 125px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .content {
          color: #707073;
        }

        svg {
          color: #9baceb;
        }

        &:focus,
        &.selected {
          border: 1px solid #aa1a5f;
          box-shadow: 0px 4px 12px 0px #fadcea;
          transform: scale(1.03);

          .planTitle {
            background: var(--7, #fff5fa);
            border: 1px solid #aa1a5f;
            border-radius: 8px 8px 0px 0px;
          }

          .months {
            border-radius: 20px;
            border: 1px solid var(--3, #d42177);
            background: var(--6, #fadcea);
          }

          .currentPlan, .currentPlan:hover {
            height: 35px;
            background-color: #f5f5f5;
            color: #00000040;
            border: 1px solid #00000040;
          }

          .choosePlan {
            border-radius: 6px;
            border: 1px solid var(--4, #e164a0);
            background: var(--7, #fff5fa);

            /* drop-shadow/button-secondary */
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          }

          .content {
            p:nth-child(1) {
              color: #aa1a5f;
            }
          }

          .payment {
            .annuallyPrice {
              color: #aa1a5f;
            }
          }

          .plan-details-modal {
            span {
              color: #aa1a5f !important;
            }
          }
        }

        .payment {
          font-size: 34px;
          font-weight: 600;
          margin-bottom: 2rem;

          span {
            font-size: 14px;
            color: #aa1a5f;
          }

          .annuallyPrice {
            font-size: 40px;
            color: #aa1a5f;
            font-weight: 700;
          }
        }

        .defaultButton, .defaultButton:hover {
          height: 35px;
          margin: 20px;
          border-radius: 6px;
          border: 1px solid var(--4, #E164A0);
          background: var(--7, #FFF5FA);
          color: var(--3, #D42177);
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
        }

        .plan-details-modal {
          span {
            color: #aa1a5f !important;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .columnPosition {
        display: flex;
        justify-content: center;
        @media only screen and (min-width: 1200px) {
          justify-content: flex-end;
        }

        .ant-radio-checked {
          .ant-radio-inner {
            border-color: #aa1a5f;
            background-color: #ffffff;
          }
          .ant-radio-inner::after {
            background-color: #aa1a5f;
          }
        }
      }
    }

    @media only screen and (min-width: 1200px) {
      padding: 40px 80px;
    }
  }
  .cancelSubscription {
    display: flex;
    justify-content: flex-end;
  }

  .paymentMethod {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 40px;

    p {
      color: #d42177;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }

    .nextPayment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid var(--4, #e164a0);
      background: var(--7, #fff5fa);
      box-shadow: 0px 1px 6px 0px #fadcea;
      text-align: center;
      min-width: 210px;
      padding: 10px 16px;

      h3 {
        color: #d42177;
        font-family: Poppins;
        font-size: 26px;
        font-weight: 600;
      }

      p {
        color: #d42177;
      }
    }

    .editPaymentMethod {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 1px 6px 0px #fadcea;
      padding: 10px 16px;

      .cardInformation {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;

        .infoDetails {
          display: flex;
          align-items: center;

          .creditCardIcon {
            width: fit-content;
            font-size: 33px;
            padding: 0 18px;
            border-radius: 6px;
            box-shadow: 0px 3px 12px 0px #fadcea;

            svg {
              border-radius: 8px;
              path {
                fill: #d42177;
              }
            }
          }

          .visaEnding {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            font-family: "Poppins";

            span:nth-child(1) {
              font-weight: 600;
            }

            span:nth-child(2) {
              color: #aa1a5f;
            }
          }
        }
        @media only screen and (min-width: 576px) {
          flex-direction: row;
        }
      }
    }
    .defaultButton {
      background-color: #eef2ff;
      width: fit-content;
    }

    @media only screen and (min-width: 1200px) {
      flex-wrap: nowrap;
    }
  }
}

.billingHistory {
  padding: 40px;

  .ant-pagination-item,
  .ant-pagination-next,
  .ant-pagination-prev {
    border: 1px solid #d9d9d9;
  }
  .ant-pagination-item-active {
    color: #aa1a5f;
    border-color: #aa1a5f;
    background-color: #d9e3ff;
  }

  .ant-table-cell-fix-right {
    box-shadow: 0px 1px 6px 0px #fadcea;
  }
}

.basicModal {
  .rejectTask {
    h4 {
      margin-bottom: 20px;
      color: #aa1a5f;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }

    span {
      font-size: 15px;
    }

    .formFields {
      span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .modalButtons {
      display: flex;
      justify-content: center;
      gap: 20px;

      button {
        padding: 4px 22px;
        box-shadow: 0 1px 6px 0 #fadcea;
        border-radius: 8px;
        height: 40px;
        font-family: Poppins;
      }
      .cancel {
        color: #aa1a5f;
        background: #fff;
        border: 1px solid #d9e3ff;
        box-shadow: none;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .primaryButton {
        border-radius: 8px;
        border: 1px solid #aa1a5f;
        background: #aa1a5f;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }
  .ant-modal-close {
    .anticon-close {
      border: none !important;
    }
  }
}

.footerButtonCustomStyle {
  .ant-modal-content {
    padding: 32px 40px 40px !important;

    h3 {
      color: #707073 #aa1a5f;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      padding: 0 !important;

      span {
        color: #aa1a5f;
      }
    }

    h4 {
      color: #aa1a5f;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      padding: 0 !important;
    }

    p {
      padding: 0 !important;
      font-weight: 500;
    }

    .alignText {
      text-align: center;
    }

    .ant-radio-checked {
      .ant-radio-inner {
        background-color: #fff;
        border-color: #aa1a5f;
      }
    }
    .ant-radio-inner::after {
      background-color: #aa1a5f;
    }
  }
}
