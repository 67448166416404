.tasksAssignment {
  .tab-container {
    display: flex;
    flex-direction: column;
  }
}

.delete-selectedBtn {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--2, #aa1a5f);
  color: var(--2, #aa1a5f);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.archived-selectedBtn {
  display: flex;
  height: 44px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--character-disabled-placeholder-25, rgba(0, 0, 0, 0.25));
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  border-radius: 8px;
  background: var(--Neutral-3, #f5f5f5);
}

.delete-selectedBtn:hover {
  color: red !important;
  border-color: red !important;
}


.archived {
  width: fit-content;
  display: flex;
  padding: 3px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--Neutrals-Gray-0, #E8E8E8);

  span {
    color: #555555;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.completed {
  width: fit-content;
  display: flex;
  padding: 3px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--system-green-sucess-400, #EEF7F2);

  span {
    color: #0E9349;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.onHold {
  width: fit-content;
  display: flex;
  padding: 3px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: linear-gradient(0deg, #FBF0E5 0%, #FBF0E5 100%), #D46600;

  span {
    color: #D46600;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.accepted {
  width: fit-content;
  display: flex;
  padding: 3px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: #EBF2FF;

  span {
    color: #3B81FE;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}

.assigned {
  width: fit-content;
  display: flex;
  padding: 3px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--system-yellow-warning-400, #FFF9F0);

  span {
    color: var(--system-yellow-warning, #FC9403);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.12px;
  }
}