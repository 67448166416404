.container {
    padding: 0 !important;
    margin: 0 !important;

    .contactsWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contactsInner {
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--6, #FADCEA);
            background: var(--7, #FFF5FA);

            h1 {
                width: 886px;
                height: 22px;
                color: var(--4, #E164A0);

                /* Basic/Subtitle */
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                /* 144.444% */
            }

            .contactDetails {
                display: flex;
                align-items: flex-start;
                gap: 32px;
                align-self: stretch;
                flex-wrap: wrap;

                .textWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    // flex: 1 0 0;
                    flex-basis: 46%;
                    // margin: 10px;
                }
            }
        }
    }
}


.contactCounts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // gap: 32px;
    align-self: stretch;

    .textWrapper {
        flex-basis: 50%;
    }
}




.historyButton {
    display: flex;
    height: 32px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--6, #FADCEA);
    border: 1px solid transparent;
    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
    color: var(--2, #AA1A5F);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    &:hover {

        border: 1px solid var(--2, #AA1A5F);
    }
}


.normalText {


    align-self: stretch;
    color: var(--Neutrals-Black, #3A3737);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.historyWrapper {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    max-height: 500px !important;
    overflow-y: scroll;
    margin-bottom: 20px !important;

    .historyCard {
        display: flex;
        padding: 8px 0px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 8px;

        .strongText {
            align-self: stretch;
            color: var(--Neutrals-Black, #3A3737);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 157.143% */
        }
    }

    .divider {
        background: #FADCEA;
    }
}