.ant-layout-header {
  line-height: 24px !important;
}
.iconWrapper {
  padding: 0 10px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutrals-Gray-200, #c1c1c1);
  display: flex;
  height: 100%;
  align-items: center;
}

.adminWrapper {
  padding: 0 10px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutrals-Gray-200, #c1c1c1);
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-evenly;
  min-width: 200px;
}

.notificationIcon {
  display: flex;
  padding: 6px;
  justify-content: flex-end;
}

.adminHeader {
  display: flex;
  width: 100%;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(221, 221, 221, 0.25);
}

.adminHeaderName {
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 10px;
}
.adminHeaderNameText {
  color: var(--Blue-purple-Dark-purple, #252938);
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
}

.adminHeaderNameTextPosition {
  color: var(--Greys-Dark-gray, #707073);
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}
.adminHeaderLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.adminHeaderCircle {
  width: 35px;
  height: 35px;
  background-color: #252938; /* Background color of the circle */
  border-radius: 50%; /* Makes the div circular */
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.adminHeaderCircleText {
  color: var(--Greys-White, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 2px;
  cursor: pointer;
}

.adminHeaderLogo.ant-dropdown,
.ant-dropdown::before {
  right: 40px !important;
  top: 80px !important;
  width: fit-content !important;
}

.adminHeaderLogo.ant-dropdown a {
  text-decoration: none !important;
}

.notificationContainer {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--Corners-corner-medium, 8px);
  background: var(--Neutrals-White, #fff);
}

.notificationsTitle {
  display: flex;
  padding: 0px 20px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: var(--Neutrals-Black, #3a3737);

  /* Basic/Subtitle */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}

.notificationTitle {
  align-self: stretch;
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
}

.ant-avatar {
  width: 24px;
  height: 24px;
  border-radius: 0;
  overflow: hidden;
}

.ant-avatar-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background: transparent;
}

.ant-list-item {
  transition: background-color 0.3s;
}

.notificationContainer .ant-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Optional hover effect */
  background: rgba(255, 237, 246, 0.7);
}

.description {
  color: var(--Neutrals-Black, #3a3737) !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.32px !important;
}

.previewTextHeader {
  color: var(--2, #aa1a5f) !important;
  font-family: Lato !important;
  font-size: 16px !important;
  background-color: transparent;
  padding: 0;
  margin-bottom: 6px;
  border: none;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.32px !important;
  text-decoration-style: solid !important;
  text-decoration-skip-ink: none !important;
  text-decoration-thickness: auto !important;
  text-underline-offset: auto !important;
  text-underline-position: from-font !important;
}
.previewTextHeader:hover {
  text-decoration-line: underline !important;
}

.timeText {
  color: var(--Neutrals-Gray-300, #979797) !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.28px !important;
}

.ant-list-split .ant-list-item {
  border-block-end: 1px solid #97979768;
}

.navMenuText {
  color: var(--10, #737373);
  text-align: center;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  &:hover {
    color: #aa1a5f;
  }
}

.topHeaderWrapper {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  justify-content: space-between !important;
}

.costumNavMenu {
  text-align: start;
}

@media only screen and (max-width: 1100px) {
  .topHeaderWrapper {
    justify-content: flex-end !important;
  }
  .costumNavMenu {
    display: none !important;
  }
}
