//tabs design 
.visitBoardComponent,
.implementationPage {
    .ant-tabs-tab {
        color: #777777;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-tab:hover {
        color: var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--2, #AA1A5F) !important;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .ant-tabs-ink-bar-animated {
        color: var(--2, #AA1A5F) !important;

        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }

    .ant-tabs-tab-active {
        border-bottom: 2px solid var(--2, #AA1A5F) !important;
    }



    .custom-tab-label {
        position: relative;
        display: inline-block;

        .draft-count {
            position: absolute;
            border: 1px solid #AA1A5F;
            background-color: #AA1A5F;
            border-radius: 50%;
            background-color: white;
            background: #AA1A5F;
            top: -8px;
            right: -22px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            line-height: 20px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }

    //buttons



    // .addButtonTPM {
    //     background: var(--1, #6A113C) !important;
    // }

    .exportButtonTPM,
    .exportButtonTPM:hover {
        background: var(--2, #6A113C) !important;
    }

    .actionDiv {
        justify-content: space-evenly;
    }

    .tableViewcontainer {
        margin: 40px 40px 40px 40px;
    }


    .tableHeader {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 80px;
        align-self: stretch;
        margin-bottom: 40px;
    }

    .tableViewHeaderText {
        color: var(--2, #AA1A5F);
        /* Basic/Title */
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        /* 145.455% */
    }

    .tableViewHeaderButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center !important;
        gap: 18px;
        flex: 1 0 0;

    }

    .customTabs .ant-tabs-tab {
        color: var(--Neutrals-Gray-400, #777);
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 160% */
        letter-spacing: -0.15px;
    }

    .customTabs .ant-tabs-tab-active {
        color: var(--2, #AA1A5F);
    }

    .filtersWrapper {
        margin: 40px 0px;
    }

    // Status style



    .progress {
        width: fit-content;
        display: flex;
        padding: 14px 10px !important;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #EBF2FF;

        span {
            color: #3B81FE;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }



    .monitoring {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: var(--system-yellow-warning-400, #FFF9F0);

        span {
            color: var(--system-yellow-warning, #FC9403);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .review {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #F5F1F8;

        span {
            color: #9670B8;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .delivered {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #DEF5F5;

        span {
            color: #045B46;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    .debrief {
        width: fit-content;
        display: flex;
        padding: 3px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: #E7F1E6;

        span {
            color: #0F7507;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
        }
    }

    thead {

        .styledTitle {
            text-wrap: nowrap;
        }



        tr {

            background: var(--7, #FFF5FA) !important;

            border-top: 1px solid var(--6, #FADCEA) !important;
        }

    }

    .filtersContainer {
        width: 100%;
        gap: 24px;
    }

}



// Style Sorter 
.ant-table-column-sorter.ant-table-column-sorter-full {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    .anticon.anticon-caret-up.ant-table-column-sorter-up,
    .anticon.anticon-caret-down.ant-table-column-sorter-down {
        margin: 0 !important;
        padding: 0 !important;
        color: #aa1a6042;
    }


    .anticon.anticon-caret-down.ant-table-column-sorter-down.active,
    .anticon.anticon-caret-up.ant-table-column-sorter-up.active {
        margin: 0 !important;
        padding: 0 !important;
        color: #AA1A5F;
    }
}



.ant-table-column-sorters {
    display: flex;
    gap: 10px;
}


thead {


    .ant-table-selection {

        .ant-checkbox {

            input[type="checkbox"] {
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                position: absolute;
                width: 18px;
                height: 18px;
                margin: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    border: 1px solid var(--2, #AA1A5F);
                    background-color: transparent;
                    position: absolute;
                    top: -9px;
                    left: -2px;
                }

                &::after {
                    content: "\2713";
                    position: absolute;
                    // top: 50%;
                    left: 40%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: var(--2, #AA1A5F);
                    opacity: 0;
                    font-weight: bold;
                    transition: opacity 0.3s ease;
                }

                &:checked {
                    &::before {
                        border: 2px solid var(--2, #AA1A5F);
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .ant-checkbox-inner {
            display: none;
        }
    }
}

.ant-table-selection-column {
    position: relative !important;
}


.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
    background: var(--7, #FFF5FA);
}

tbody {



    .ant-checkbox-wrapper {
        min-width: 15px !important;

        .ant-checkbox {

            input[type="checkbox"] {
                appearance: none !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                width: 18px;
                height: 18px;
                margin: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    border: 1px solid var(--Neutrals-Gray-200, #C1C1C1);
                    background-color: transparent;
                    position: absolute;
                    top: -9px;
                    left: 5px;
                }

                &::after {
                    content: "\2713";
                    position: absolute;
                    // top: 50%;
                    left: 80%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: var(--Neutrals-Gray-200, #656565);
                    opacity: 0;
                    font-weight: bold;
                    transition: opacity 0.3s ease;
                }

                &:checked {
                    &::before {
                        border: 2px solid var(--Neutrals-Gray-200, #656565);
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .ant-checkbox-inner {
            display: none;
        }
    }



}


thead {
    .ant-table-cell-fix-right-first {
        filter: drop-shadow(-1px 0px 5px #9f9e9e);
        width: 160px !important;
    }
}

.reviewPlanning {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #FFF9F0;

    span {
        color: var(--system-yellow-warning, #FC9403);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}

.prePhase {
    width: fit-content;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
    background: #DFE6FF;

    span {
        color: #325899;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
    }
}