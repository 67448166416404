.IpSummaryDiv {
  padding: 60px 40px 80px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.ipSummaryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.ipSummaryRight {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 22px;
  flex-wrap: wrap;
}

.implementing-partner {
  color: var(--Neutrals-Black, #3a3737);
  margin-top: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.circleIpSummary {
  display: flex;
  width: 41px;
  height: 41px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--6, #fadcea);
}

.ipSummaryNameLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.buttonEditIpName,
.buttonEditIpName:hover {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--7, #fff5fa) !important;
  background: transparent !important;
}

.profile-container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.profile-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.ipSummaryProjectInfo {
  display: flex;
  padding: 32px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  border-right: 1.5px solid var(--6, #fadcea);
  border-bottom: 1.5px solid var(--6, #fadcea);
  border-left: 1.5px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
  flex-wrap: wrap;
}

.ipNameHeader {
  color: var(--9, #404040);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  /* 150% */
}

.ipNameLabel {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.ipNameValue {
  color: var(--Neutrals-Black, #3a3737);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 122.222% */
}

.activitySnapshotHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;

  .ant-radio-checked .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner:hover {
    border-color: #aa1a5f !important;
  }

  .ant-radio-checked .ant-radio-inner::after,
  .ant-radio-checked .ant-radio-inner:hover {
    background-color: #aa1a5f !important;
    color: #aa1a5f !important;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: white;
  }
}

.radioButtonGroup {
  display: flex;
  align-items: center;
}

.ipCardDiv {
  margin-top: 20px;
  display: flex;
  padding: 40px 32px;
  margin-bottom: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
}

.ipCardName {
  color: var(--2, #aa1a5f);

  /* H3 - 22pt */
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 118.182% */
}

.ipCards {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
}

.ipCardCarousel {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  flex-basis: 25%;
}

@media (max-width: 1390px) {
  .ipCardCarousel {
    flex-basis: 100%;
  }
}

.cardIp {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--5, #f5b0d2);
  background: var(--15, #fff);
  box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
}

.ipCardList {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
}

.ipCardHeadingName {
  color: var(--8, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 00;
  line-height: normal;
}

.ipCardTextName {
  color: var(--9, #404040);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ipCardTextName span {
  font-weight: bold;
}

.ipCardSeeMore {
  color: var(--4, #e164a0);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.resourceSection {
  display: flex;
  padding: 48px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
  flex-wrap: wrap;
}

.resourceReport {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
}

.resourceReportCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
}

.resourceReportList {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--13, #e6e6e6);
  background: var(--14, #f6f6f6);
  flex-wrap: wrap;

  .boldText {
    font-weight: bold;
    /* Make the text bold */
  }
}

.ipSummaryTable,
.locationRatings {
  display: flex;

  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.5px solid var(--6, #fadcea);
  background: var(--Greys-White, #fff);
  width: 100%;
}

.mapAndGraphic {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  width: 100%;
}

.graphic {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: #fff;
}

.map {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1.5px solid var(--13, #e6e6e6);
  background: #fff;
}

@media (max-width: 768px) {
  .mapAndGraphic {
    flex-direction: column;
    gap: 16px;
  }
}