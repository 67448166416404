.kanbanBoard {
  ::-webkit-scrollbar {
    height: 8px;
    /* Change this value to adjust the height */
  }

  ::-webkit-scrollbar-track {
    background: var(--7, #FFF5FA);
    /* Color of the track */
  }

  ::-webkit-scrollbar-thumb {
    background: var(--2, #AA1A5F);
    /* Color of the thumb */
    border-radius: 4px;
    /* Makes the thumb rounded */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--2, #AA1A5F);
    ;
    /* Color of the thumb when hovered */
  }

  .cardMainDiv {
    display: flex !important;
    flex-direction: row !important;
    overflow-x: scroll !important;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding: 16px;

    .cardHeader {
      border-radius: 8px;
      min-width: 240px;
      max-width: 260px;
      background-color: #E6E6E6;

      color: var(--15, #FFF) !important;

      box-shadow: 0px 4px 6px 0px rgba(106, 17, 60, 0.12);

      .cardHeaderText {
        border-radius: 8px 8px 0px 0px;
        width: 100%;

        h2 {

          padding: 10px;
          color: var(--15, #FFF);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 144%;
          /* 23.04px */
        }
      }

    }

    .cardWrapper {
      display: flex;
      padding: 16px 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;

      .card {
        max-width: 230px;
        max-width: 240px;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 8px;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--13, #dcd9d9);
        background: var(--15, #FFF);
        overflow: hidden;

        h1 {
          align-self: stretch;
          color: var(--8, #000);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        p {
          color: var(--9, #404040);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: var(--9, #404040);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .buttonWrapper {
          width: 100%;
          display: flex;
          justify-content: center;

          button {
            border-radius: 6px;

            color: var(--4, #E164A0);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
            border-radius: 8px;
            padding: 8px;
            background: var(--15, #FFF);

            border: 1px solid var(--13, #e6e6e600);

            img {
              width: 16px !important;
              height: 16px !important;
            }
          }

          a:hover {
            border: 1px solid var(--13, #E6E6E6);
          }
        }
      }
    }
  }
}