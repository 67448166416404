    //Modal Style

    .tripLogs {

        .tripCardDetails {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 24px;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            border-radius: 8px;
            background: var(--14, #F6F6F6);
            margin: 32px 0;

            .showAllList {
                overflow: hidden !important;
                max-height: 180px !important;
            }

            .list {
                list-style: none;
                text-decoration: none;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                transition: all 1s ease-in-out;

                p {
                    color: var(--9, #404040);
                    font-family: Lato !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 144%;

                    /* 20.16px */
                    span {
                        color: var(--9, #404040);
                        font-family: Lato !important;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 144%;
                    }
                }



            }

            .seeMoreWrapper {

                height: 32px;
                padding: 4px 12px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                a {
                    color: var(--2, #AA1A5F);
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 157.143% */
                    border-radius: 6px;
                }
            }

            .tripCardDetailsHeader {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 6px;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;
                align-self: stretch;
                border-bottom: 1px solid var(--12, #BFBFBF);

                a {
                    display: flex;
                    flex-wrap: wrap;
                    height: 32px;
                    padding: 4px 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    border-radius: 6px;
                    background: var(--2, #AA1A5F);

                    /* Elevation/Buttons */
                    box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.10);
                    color: var(--character-primary-inverse, #FFF);
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 157.143% */
                }
            }
        }

        .switchHolder {
            display: flex;
            padding-bottom: 24px;
            align-items: flex-start;
            align-content: flex-start;
            gap: 32px;
            align-self: stretch;
            flex-wrap: wrap;
        }

        .overNightOn {

            display: block !important;
        }

        .overNightDetailsWrapper {
            display: none;
            transition: all ease-in-out 0.8s !important;

            .overNightDetails {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 24px;
                align-items: flex-start;
                align-content: flex-start;
                gap: 32px;
                flex-wrap: wrap;
                border-radius: 8px;
                border: 1px solid var(--13, #E6E6E6);
                background: var(--14, #F6F6F6);
                margin-bottom: 24px;

                div {
                    width: 100%;
                }
            }
        }

        .buttonsModal {
            width: 100%;
        }

        .overNightOtherInfo {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            width: 100%;
            margin: 24px 0;

            input {
                width: 100%;
            }
        }
    }

    .footer-buttons {
        // padding-top: 40px;
        display: flex;
        justify-content: center;
        gap: 20px;

        .cancel-workspace-button {
            display: flex;
            height: 44px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--2, #aa1a5f) !important;
            color: var(--2, #aa1a5f) !important;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }

        .add-workspace-button {
            display: flex;
            height: 44px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border-color: #aa1a5f !important;
            background: var(--2, #aa1a5f) !important;
            color: var(--character-primary-inverse, #fff) !important;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
        }
    }

    .custom-checkbox .ant-checkbox-inner {
        background-color: #fff !important;
    }

    .custom-checkbox:hover .ant-checkbox-inner,
    .custom-checkbox:focus .ant-checkbox-inner {
        border-color: #AA1A5F !important;
    }

    .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #AA1A5F !important;
        border-color: #AA1A5F !important;
    }

    .custom-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner {
        background-color: #AA1A5F !important;
        border-color: #AA1A5F !important;
    }

    .custom-checkbox .ant-checkbox-checked::after {
        border-color: #AA1A5F !important;
    }