.selectFileType {
  display: flex;
  width: 100%;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid var(--6, #fadcea);
  background: var(--7, #fff5fa);
  margin-bottom: 32px;
}

.exportNote {
  display: flex;
  width: 100%;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: var(--6, #fadcea);
  color: var(--2, #aa1a5f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
