.ant-modal-confirm {

    width: fit-content !important;

    .ant-modal-confirm-body {
        display: flex;
        align-items: flex-start;
        gap: var(--Corners-corner-medium, 8px);
        // margin: 24px;

        img {
            margin-top: 1px;
        }
    }



    .ant-modal-confirm-title {
        color: var(--2, #AA1A5F) !important;
        text-align: center !important;
        font-family: Poppins !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 600 !important;
    }

    .ant-modal-confirm-paragraph {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .ant-modal-confirm .ant-modal-confirm-content {
        p {
            align-self: stretch;
            color: var(--Neutrals-Black, #3A3737);
            text-align: center;
            font-family: Lato;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .ant-modal-confirm-btns {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
    }
}

.ant-modal-close {
    display: flex;
    justify-content: flex-end; // Align close icon to the right
}

.custom-close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;

    &:hover {
        opacity: 0.7; // Optional: hover effect
    }
}

.ant-modal-confirm-body-wrapper {
    padding: 16px 24px 24px 24px;
}